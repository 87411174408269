(function() {
  //get html elements
  let theme = sessionStorage.getItem('theme');
  let html = document.documentElement;
  let body = document.querySelector('#lightsetting');

  //set theme
  theme === 'dark' ? html.classList.add('dark') : html.classList.remove('dark');

  //restore body display
  body.classList.remove('hidden')
})();